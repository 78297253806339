
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo/seo-new"


export default function Privacy() {
    return (
        <Layout>
            <SEO
                title="Privacy"
                description="Privacy policy for taxomate"
                image="https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg"
                pathname='/privacy/'
                originalPathname='/privacy/'
            />
            <iframe height="1400px" width="100%" frameborder="0" src="https://www.iubenda.com/privacy-policy/77958510"></iframe>

        </Layout>
    );
}